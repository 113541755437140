<template>
  <WeContainer card="" v-if="ready">
    <div class="row">
      <div class="col-12 col-lg-2 sticky-tabs">
        <Navigation v-bind:tabs="tabs" />
      </div>
      <div class="col">
        <WeCard class="sticky-header shadow-sm">
          <div class="row align-items-center">
            <div class="col">
              <div class="sticky-header-content">
                <span>Ana Sayfa Vitrin Yönetimi</span>
              </div>
            </div>
            <div class="col-auto">
              <div class="d-flex">
                <div class="dropdown ml-auto">
                  <button
                    class="btn btn-primary dropdown-toggle"
                    data-toggle="dropdown"
                  >
                    <span>Alan Ekle</span>
                  </button>
                  <div class="dropdown-menu">
                    <a
                      class="dropdown-item cursor-pointer"
                      v-on:click="addBlock"
                    >
                      <i class="fas fa-th-large"></i>
                      <span>Blok</span>
                    </a>
                    <a
                      class="dropdown-item cursor-pointer"
                      v-on:click="addCategory"
                    >
                      <i class="fas fa-th-list"></i>
                      <span>Kategori</span>
                    </a>
                    <a
                      class="dropdown-item cursor-pointer"
                      v-on:click="addBanner"
                    >
                      <i class="fas fa-image"></i>
                      <span>Banner</span>
                    </a>
                  </div>
                </div>
                <div class="d-flex ml-3">
                  <WeLanguageSwitcher v-model="showcaseManagement.lang" />

                  <WeSubmitButton
                    class="ml-2"
                    v-bind:submit-status="submit"
                    v-on:submit="saveBlocks"
                  />
                </div>
              </div>
            </div>
          </div>
        </WeCard>
        <WeCard class="shadow-sm draggable-content mt-3">
          <WeCard class="alert-info" v-if="!tabs || !tabs.length">
            <i class="fas fa-info-circle fa-lg"></i>
            <b class="ml-2">Lütfen Alan Ekleyin</b>
          </WeCard>
          <draggable v-model="tabs">
            <transition-group name="block" type="transition">
              <div
                v-for="(element, index) in tabs"
                :key="(element.id || tabs.length + 1) + '-block'"
                class="draggable-block mb-2"
              >
                <i class="fa fa-arrows-alt" />
                <input
                  v-model="element.title"
                  readonly="true"
                  onkeydown="this.style.width = (this.value.length + 2 ) + 'ch';"
                  placeholder="Blok ismi giriniz.."
                  ref="blockName"
                />
                <i
                  class="fa fa-edit name-edit"
                  v-on:click="editBlockName($event)"
                />
                <span class="mr-3 badge badge-pill badge-success">{{
                  getElementType(element)
                }}</span>
                <WeSwitch
                  v-bind:show-status="true"
                  v-bind:value="element.is_active"
                  v-on:change="changeBlockActive(index)"
                />
                <a
                  v-if="element.id"
                  v-bind:href="'/showcase-management/' + element.id"
                  class="btn btn-light ml-3 edit-showcase"
                >
                  <i class="fa fa-edit mr-2" />
                </a>
                <a
                  class="btn ml-3 draggable-trash"
                  v-if="!element.is_required"
                  v-on:click="removeBlock(index)"
                >
                  <i class="fa fa-trash mr-2" />
                </a>
              </div>
            </transition-group>
          </draggable>
        </WeCard>
      </div>
    </div>
    <WeModal
      modalWidth="30"
      title="Kategori Ekle"
      class="categoryModal"
      v-if="categoryModal"
      v-on:close="closeModal"
    >
      <div slot="body">
        <WeCategory
          v-bind:current-category="$route.params.id"
          v-bind:multiple="false"
          v-bind:draggable="true"
          v-bind:showSelectedCategories="false"
          v-bind:placeholder="$t('searchCategory')"
          v-bind:language="showcaseManagement.lang"
          ref="categorySelector"
        />
        <button
          class="btn btn-primary ml-auto d-block mt-4"
          v-on:click="addCategoryToBlock"
        >
          <i class="fa fa-plus mr-2" />
          <span>Ekle</span>
        </button>
      </div>
    </WeModal>
  </WeContainer>
  <WeLoading v-else />
</template>
<style lang="scss" scoped>
.categoryModal .custom-card-title {
  display: none;
}
.categoryModal .card {
  border: none;
}
.categoryModal .modal-body {
  padding: 0;
}
.categoryModal .card-body {
  padding: 0;
}
.sticky-tabs {
  position: sticky;
  top: -2px;
  height: max-content;
  margin-bottom: -16px;
}
.sticky-header {
  top: -2px;
  position: sticky;
  z-index: 10;
}
.sticky-header-content {
  display: flex;
  align-items: center;
  width: 100%;
  padding-left: 16px;
  padding-right: 16px;
}
.sticky-header-content > span {
  font-size: 17px;
  font-weight: 600;
}
.draggable-block {
  border: 1px solid #dfdfdf;
  border-radius: 4px;
  padding: 12px;
  padding-left: 24px;
  padding-right: 24px;
  display: flex;
  align-items: center;
  cursor: grab;
}
.draggable-block:hover {
  background-color: #fbfbfb;
}
.draggable-block:hover .name-edit {
  display: block;
}
.draggable-block > i {
  color: #3f51b5;
  margin-right: 24px;
  font-size: 16px;
}

.draggable-block > input {
  font-size: 15px;
  font-weight: 600;
  min-width: 20ch !important;
  border: none;
  background: transparent;
  max-width: 700px;
  cursor: grab;
}
.draggable-block a i {
  margin: 0 !important;
}

.draggable-block .custom-switch {
  margin-right: 16px;
}

.draggable-block > *:nth-child(4) {
  margin-left: auto !important;
}

.draggable-trash {
  background: #fef5f7;
  color: #dc3545;
}

.draggable-trash:hover {
  background: #dc3545;
  color: #fff;
}
.name-edit {
  display: none;
  color: #3f51b5 !important;
  cursor: pointer;
}
</style>
<script>
import Navigation from "../Navigation/Index";
import draggable from "vuedraggable";
import { mapActions, mapState } from "vuex";

export default {
  name: "Blocks",
  data() {
    return {
      reactiveWidth: 100,
      tabs: [],
      ready: false,
      submit: false,
      categoryModal: false,
    };
  },
  components: {
    Navigation,
    draggable,
  },
  methods: {
    ...mapActions("showcaseManagement", ["getHomeBlocks", "setHomeBlocks"]),
    removeBlock(index) {
      this.$swal({
        title: "Bu vitrini kaldırmak istediğinizden emin misiniz?",
        showCancelButton: true,
        cancelButtonText: "İptal",
        confirmButtonText: "Sil",
        icon: "warning",
      }).then((confirm) => {
        if (confirm.isConfirmed) {
          this.tabs.splice(index, 1);
        }
      });
    },
    editBlockName(event) {
      let currentClass = event.target.parentElement
        .querySelector(".name-edit")
        .getAttribute("class")
        .includes("fa-edit");

      var input = event.target.parentElement.querySelector("input");

      if (currentClass) {
        input.removeAttribute("readonly");
        event.target.parentElement
          .querySelector(".name-edit")
          .setAttribute("class", "fa fa-check name-edit");
        input.focus();
        input.setAttribute("style", "cursor:text");
      } else {
        input.setAttribute("readonly", "true");
        event.target.parentElement
          .querySelector(".name-edit")
          .setAttribute("class", "fa fa-edit name-edit");
        input.setAttribute("style", "cursor:grab");
      }
    },
    addBlock() {
      this.tabs.push({
        name: "",
        type: "block",
        is_active: true,
      });

      setTimeout(() => {
        this.$refs.blockName[this.tabs.length - 1].removeAttribute("readonly");
        this.$refs.blockName[this.tabs.length - 1].focus();
        this.$refs.blockName[this.tabs.length - 1].setAttribute(
          "style",
          "cursor:text"
        );

        this.$refs.blockName[this.tabs.length - 1].parentElement
          .querySelector(".name-edit")
          .setAttribute("class", "fa fa-check name-edit");
      }, 250);
    },
    addCategory() {
      this.categoryModal = true;
    },
    addBanner() {
      this.tabs.push({
        name: "",
        type: "banner",
        is_active: true,
      });

      setTimeout(() => {
        this.$refs.blockName[this.tabs.length - 1].removeAttribute("readonly");
        this.$refs.blockName[this.tabs.length - 1].focus();
        this.$refs.blockName[this.tabs.length - 1].setAttribute(
          "style",
          "cursor:text"
        );

        this.$refs.blockName[this.tabs.length - 1].parentElement
          .querySelector(".name-edit")
          .setAttribute("class", "fa fa-check name-edit");
      }, 250);
    },
    saveBlocks() {
      function isBlank(str) {
        return !str || /^\s*$/.test(str);
      }
      let request = [];
      let blankStatus = false;
      this.tabs.forEach((tab, index) => {
        if (isBlank(tab.title)) {
          this.$toast.error("Lütfen Blok İsmi Değerlerini Giriniz...");
          blankStatus = true;
          return false;
        }
        var requestObject = {
          title: tab.title,
          index: index,
          is_active: Number(tab.is_active),
          type: tab.type,
        };

        if (tab.id) {
          requestObject.id = tab.id;
        }

        if (tab.is_required) {
          requestObject.is_required = tab.is_required;
        }
        if (tab.category_id) {
          requestObject.category_id = tab.category_id;
        }

        request.push(requestObject);
      });
      if (!blankStatus) {
        this.submit = true;
        request = request.sort((a, b) => a.index - b.index);
        this.setHomeBlocks({
          request,
          onSuccess: (result) => {
            if (result && result.data && result.data.status !== "error") {
              this.$toast.success("Vitrin Kaydedildi");
              this.getBlocks();
            } else {
              this.$toast.error("Vitrin Kaydedilemedi");
            }
          },
          onFinish: () => {
            this.submit = false;
          },
          onError: (error) => {
            this.$toast.error("Vitrin Kaydedilemedi");
          },
        });
      }
    },
    closeModal() {
      this.categoryModal = false;
    },
    changeBlockActive(index) {
      this.tabs[index].is_active = !this.tabs[index].is_active;
    },
    addCategoryToBlock() {
      let name = this.$refs.categorySelector.selected[0].name;
      if (name && name != "") {
        this.categoryModal = false;
        this.tabs.push({
          title: this.$refs.categorySelector.selected[0].name,
          type: "category",
          is_active: true,
          category_id: this.$refs.categorySelector.selected[0].id,
        });
        setTimeout(() => {
          this.$refs.blockName[this.tabs.length - 1].removeAttribute(
            "readonly"
          );
          this.$refs.blockName[this.tabs.length - 1].focus();
          this.$refs.blockName[this.tabs.length - 1].setAttribute(
            "style",
            "cursor:text"
          );

          this.$refs.blockName[this.tabs.length - 1].parentElement
            .querySelector(".name-edit")
            .setAttribute("class", "fa fa-check name-edit");
        }, 250);
      }
    },
    getBlocks() {
      this.getHomeBlocks({
        onSuccess: (result) => {
          this.tabs = result?.data.items;
        },
        onFinish: () => {
          this.ready = true;
        },
      });
    },
    getElementType(element) {
      let result = "Blok";

      if (!element.name) {
        switch (element.type) {
          case "category":
            result = "Kategori";
            break;

          case "banner":
            result = "Banner";
            break;
        }
      } else {
        switch (element.name) {
          case "best-seller":
            result = "Çok Satanlar";
            break;

          case "featured":
            result = "Öne Çıkanlar";
            break;

          case "new":
            result = "Yeni Eklenenler";
            break;
        }
      }

      return result;
    },
  },
  computed: {
    ...mapState(["showcaseManagement"])
  },
  mounted() {
    this.getBlocks();
  },
  watch: {
    "showcaseManagement.lang": {
      handler: function (newVal, oldVal) {
        if (newVal !== oldVal) {
          this.ready = false;
          localStorage.setItem('showcaseManagement_lang', newVal);

          this.getBlocks();
        }
      },
    },
  },
};
</script>
